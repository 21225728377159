@font-face {
  font-family: 'Red Hat Display';
  src:
    local('RedHatDisplay-Light'),
    url('assets/fonts/RedHatDisplay/RedHatDisplay-Light.ttf') format('ttf');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src:
    local('RedHatDisplay-Regular'),
    url('assets/fonts/RedHatDisplay/RedHatDisplay-Regular.ttf') format('ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src:
    local('RedHatDisplay-Medium'),
    url('assets/fonts/RedHatDisplay/RedHatDisplay-Medium.ttf') format('ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src:
    local('RedHatDisplay-SemiBold'),
    url('assets/fonts/RedHatDisplay/RedHatDisplay-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src:
    local('RedHatDisplay-Bold'),
    url('assets/fonts/RedHatDisplay/RedHatDisplay-Bold.ttf') format('ttf');
  font-weight: 700;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  background-image: url(assets/images/bg.png);
  background-size: cover;
  background-position: 30% 70%;
  position: relative;
}

code {
  font-family: 'Red Hat Display', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.6s ease;
  &:hover {
    color: #6dffd5;
  }
}
